// 662932143
import React, { useState, useEffect } from "react";
import NavBar from "../HomePage/Navbar";
import Footer from "../HomePage/footer";
import referClasses from "./userdetails.module.css";
import { useNavigate } from "react-router-dom";
import Usernav from "./Usernav";
import { useForm } from "react-hook-form";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail, MdPhoneIphone } from "react-icons/md";
import user from "../Assets/images/user.svg";
import { FiCamera } from "react-icons/fi";
import { Services } from "../services";
import { toast } from "react-toastify";
function Refer() {
    const Navigate = useNavigate();
    const [userDetails, setUserDetails] = useState();
    const [profilepic, setprofilepic] = useState(user);
    const [base64, setbase64] = useState(undefined);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (sessionStorage.token !== null) {
            const userdata = JSON.parse(sessionStorage.getItem("userdetails"));
            setUserDetails(userdata);
            if (userdata?.ProfilePic !== "NA") {
                setprofilepic(userdata?.ProfilePic);
            }
            reset({
                MobileNumber: userdata?.MobileNumber,
                FirstName: userdata?.FirstName,
                EmailID: userdata?.EmailID,
            });
        }
    }, []);
    const profilechange = (e) => {
        setprofilepic(URL.createObjectURL(e.target.files[0]));
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setbase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const onSubmit = (data) => {
        let body = { ...data, Category: 1, FcmToken: "NA", ProfilePic: base64 };
        Services.UpdateUserDetails(JSON.stringify(body), {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        })
            .then((res) => {
                if (res.Status == 1) {
                    toast.success(`${res.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    fetch(`${process.env.REACT_APP_BASE_URL}/user/me`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: `token ${sessionStorage.getItem(
                                "token"
                            )}`,
                        },
                    })
                        .then((res) => res.json())
                        .then((resp) => {
                            if (resp.Status == 1) {
                                setUserDetails(resp?.userDetails);
                                sessionStorage.setItem(
                                    "userdetails",
                                    JSON.stringify(resp?.userDetails)
                                );
                                window.location.reload(true);
                            }
                            console.log(resp);
                        });
                }
            })
            .catch((err) => {
                alert("something went wrong try again later");
            });
        console.log(data);
    };
    return (
        <div className={referClasses["support-wrapper"]}>
            <NavBar isActive="userdata" />
            <div className={referClasses["support-content"]}>
                <div className={referClasses["support-content-leftside"]}>
                    <Usernav />
                </div>
                <div className={referClasses["support-content-rightside"]}>
                    <h3>Edit Profile</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={referClasses.imageupload}>
                            <img
                                src={profilepic}
                                className={referClasses.profile_pic_image}
                                alt=""
                            />
                            <label
                                htmlFor="file-input"
                                className={referClasses.profilepic_icon}>
                                <FiCamera size={20} color="white" />
                            </label>

                            <input
                                id="file-input"
                                type="file"
                                accept="image/jpeg, image/png,.jpeg, .png"
                                onChange={profilechange}
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                className={
                                    errors?.FirstName &&
                                    referClasses["input-error"]
                                }
                                type="text"
                                placeholder="Enter Name"
                                {...register("FirstName", {
                                    required: "Enter Name",
                                    maxLength: 20,
                                })}
                            />
                            {errors?.FirstName && (
                                <p style={{ color: "red" }}>
                                    {errors?.FirstName?.Message}
                                </p>
                            )}
                            <FaUserAlt
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                className={
                                    errors?.MobileNumber &&
                                    referClasses["input-error"]
                                }
                                type="number"
                                disabled
                                placeholder="Enter Mobile Number"
                                {...register("MobileNumber", {
                                    required: "Enter Mobile Number",
                                    maxLength: 10,
                                })}
                            />
                            {errors?.MobileNumber && (
                                <p style={{ color: "red" }}>
                                    {errors?.MobileNumber?.Message}
                                </p>
                            )}
                            <MdPhoneIphone
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                type="email"
                                className={
                                    errors?.EmailID &&
                                    referClasses["input-error"]
                                }
                                placeholder="Enter Email"
                                {...register("EmailID", {
                                    required: "Enter Email",
                                })}
                            />
                            {errors?.Email && (
                                <p style={{ color: "red" }}>
                                    {errors?.EmailID?.Message}
                                </p>
                            )}
                            <MdEmail
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Refer;
