import React, { useState } from "react";
import CourseClasses from "../courses/course.module.css";
import Navbar from "../HomePage/Navbar";
import Footer from "../courses/courseFooter";
import PrivacyClasses from "./privacy.module.css";

function Privacy() {
    return (
        <div className={CourseClasses["CourseWrapper"]}>
            <Navbar />
            <div className={PrivacyClasses["policy-wrapper"]}>
                <h1>VETeach Privacy Policy</h1>
                <div className={PrivacyClasses["policy"]}>
                    <h2>
                        Use of information for access to Site and/or the App
                        and/or services of VETeach.
                    </h2>
                    <ul>
                        <li>
                            The information collected is relevant and necessary
                            to understand you or your online browsing search
                            pattern.
                        </li>
                        <li>
                            The information is collected with an intention to
                            provide you with updated information, help regarding
                            usage of the Site, the App or new services, offers.
                        </li>
                        <li>
                            If any, that we think you might be interested in, to
                            carry out follow ups, to assist with technical
                            support issues, to enforce the Terms and Conditions
                            of VETeach or to prevent fraud and unlawful use
                        </li>
                        <li>
                            We will use the information provided by you only for
                            the purposes for which it is consensually collected,
                            as more particularly described hereunder and/or
                            indicated to you while using/ accessing the Site
                            and/or the App.
                        </li>
                        <li>
                            To avail certain services on the Site and/or the
                            App, users are required to provide some personal and
                            sensitive information for the registration process
                            including without limitation) your name, b) email
                            address, c) phone number, d) access to your phone’s
                            text messages (SMS)inbox records/ logs) phone
                            contacts, f) location information (GPS, IP address
                            and cell tower location), g) information pertaining
                            to your mobile device and your usage thereof
                            (including the names of other applications installed
                            on your mobile device and how you use them,
                            information about your use of features or functions
                            on your device),and any other such information as
                            required.
                        </li>
                        <li>
                            We collect the aforementioned information when you
                            download and install the App and give the permission
                            to read information from your device.
                        </li>
                        <li>
                            The Information as supplied by the users enables us
                            to improve the Site and/or the App and provide you
                            the most user-friendly experience.
                        </li>
                        <li>
                            Further, we collect information about your SMSs to
                            help us to keep track of multiple accounts of our
                            users in one platform and also to establish their
                            financial status and history.
                        </li>
                        <li>
                            All required information is service dependent, and
                            we may use the above said user Information to
                            maintain, protect, and improve the Site and/or the
                            App and for developing new services
                        </li>
                        <li>
                            We may also use your phone number, email address or
                            other personal and sensitive information to send
                            commercial or marketing messages without your
                            consent.
                        </li>
                        <li>
                            We may, however, use your email address and phone
                            number without further consent for non-marketing or
                            administrative purposes (such as notifying you of
                            major changes, for customer service purposes,
                            providing information about updates to the Site
                            and/or the App, etc.).
                        </li>
                        <li>
                            Any personal and sensitive information provided by
                            you will not be considered as sensitive if it is
                            freely available and/or accessible in the public
                            domain. Further, any reviews, comments, messages,
                            blogs posted/uploaded/conveyed/communicated by users
                            on the public sections of the Site or an Application
                            Store (like the App Store or Play Store) becomes
                            published content and is not considered personal and
                            sensitive information subject to this Privacy
                            Policy.
                        </li>
                        <li>
                            In case you choose to decline to submit personal and
                            sensitive information on the Site/App, we may not be
                            able to provide certain services on the Site/App to
                            you.
                        </li>
                        <li>
                            We will make reasonable efforts to notify you of the
                            same at the appropriate time. In any case, we will
                            not be liable and or responsible for the denial of
                            certain services to you for lack of you providing
                            the necessary personal information.
                        </li>
                        <li>
                            When you register with the Site and/or the App, we
                            may contact you from time to time about updating of
                            your personal information to provide such features
                            that we believe may benefit/interest you.
                        </li>
                        <li>
                            We do not sell or rent your information, including
                            the Personal Information (as defined below).We do
                            not share your information outside of Future Group,
                            other than as necessary to fulfill your request or
                            enhancement of services through third party
                            application/software.
                        </li>
                    </ul>
                </div>

                <div className={PrivacyClasses["policy"]}>
                    <h2>Services/ Payment</h2>
                    <p>
                        While availing any of the payment method/s available on
                        the Platform, we will not be responsible or assume any
                        liability, whatsoever in respect of any loss or damage
                        arising directly or indirectly to you due to:
                    </p>
                    <ul>
                        <li>Lack of authorization for any transactions</li>
                        <li>
                            Exceeding the preset limit mutually agreed by You
                            and between "Bank/s".
                        </li>
                        <li>
                            Any payment issues arising out of the transaction
                        </li>
                        <li>Decline of transaction for any other reason/s</li>
                        <li>
                            All payments made against the purchases/services on
                            Platform by you shall be compulsorily in Indian
                            Rupees acceptable in the Republic of India. Platform
                            will not facilitate transaction with respect to any
                            other form of currency with respect to the purchases
                            made on Platform
                        </li>
                    </ul>
                </div>
                <div className={PrivacyClasses["policy"]}>
                    <h2>AMENDMENTS TO PRIVACY POLICY</h2>
                    <ul>
                        <li>
                            We reserve the right, in our sole discretion, to
                            change, modify, add or delete portions of this
                            Privacy Policy at any time without notice, and it is
                            your responsibility to review this Privacy Policy
                            from time to time on the Site and/or the App, in
                            order to keep track and take note of the changes.
                        </li>
                        <li>
                            Your use of the Site and/or the App following any
                            amendment of this Privacy Policy will signify and
                            constitute your consent to and acceptance of such
                            revised this Privacy Policy. We will not file or
                            store a copy of these terms for each interaction on
                            transaction by you through the Site and/or the App.
                            We therefore recommend that you save a copy of this
                            Privacy Policy for future reference, and ensure that
                            such copies can be reliably verified as being
                            effective (i.e. published on the Site and/or the
                            App) on a particular date.
                        </li>
                    </ul>
                </div>
                <div className={PrivacyClasses["policy"]}>
                    <h2>
                        You can do the following at any time by contacting us
                        via the email address or phone number provided as above
                        to:
                    </h2>
                    <ul>
                        <li>
                            Check and edit your profile information, including
                            Account Information;
                        </li>
                        <li>Check what data we have about you, if any;</li>
                        <li>
                            {" "}
                            Change and/or correct any data we have about you;
                        </li>
                        <li>Delete any data we have about you.</li>
                        <li>
                            Express any concern you have about our use of your
                            data.
                        </li>
                    </ul>
                    <p>
                        We will collect and retain information about your
                        transactions and service requests based on Cookies,
                        network information and device information to provide
                        adequate security, prevent fraudulent transactions and
                        for audit and legal purposes which will not be revealed
                        in public.
                    </p>
                </div>
                <div className={PrivacyClasses["policy"]}>
                    <h2>DISPUTES</h2>
                    <ul>
                        <li>
                            Any disputes over inter alia collection, storage and
                            handling of Personal Information will be governed by
                            this Privacy Policy, Terms and Conditions and by the
                            laws of India and courts of Mumbai shall have
                            exclusive jurisdiction.
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Privacy;
