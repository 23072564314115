import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile from "../Assets/images/user.svg";
import pic from "../Assets/images/studentpic.svg";

import homePageClasses from "./homepage.module.css";
class Services extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            arrow: false,

            responsive: [
                // {
                //     breakpoint: 1600,
                //     settings: {
                //         slidesToShow: 3,
                //         slidesToScroll: 3,
                //         infinite: false,
                //         dots: true,
                //     },
                // },
                {
                    breakpoint: 1340,
                    settings: {
                        infinite: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 0,
                        dots: true,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        // centerMode: true,
                    },
                },
            ],
        };
        return (
            <div className={homePageClasses.slider}>
                <Slider {...settings}>
                    <div
                        className={homePageClasses.student_review}
                        style={{ width: "70%" }}>
                        <div className={homePageClasses.student_detail}>
                            <img src={profile} alt="profilepic" />
                            <p>Swathi</p>
                        </div>
                        {/* <img src={pic} alt="img" /> */}
                        <p>
                            Hi, my name is Swathi , i am studying 3rd year ECE ,
                            i am terrified before my semester exams , donot know
                            what to study and where to study from . i approached
                            veteach for help , they are very friendly, first
                            they comforted me with good confidence , they gave
                            me 25 most expected questions i.e 5 questions from
                            each unit , I prepared only those questions and to
                            my surprise ,80% of the exams questions came from
                            veteach most expected questions .
                        </p>
                    </div>
                    <div className={homePageClasses.student_review}>
                        <div className={homePageClasses.student_detail}>
                            <img src={profile} alt="profilepic" />
                            <p>Vinay kumar</p>
                        </div>
                        {/* <img src={pic} alt="img" /> */}
                        <p>
                            Hi , my name is Vinay kumar , i am final year B.tech
                            student from mechanical stream . i came to know
                            about veteach from my friend , i had 4 backlogs in
                            2nd and 3rd year and made several attempts to clear
                            the exam , i was so depressed that lost hope that i
                            could ever clear the exams , i came to know about
                            vetech from social media and i approached them ,
                            they are very cordial , the first thing they told to
                            me is “fear not” . i took mentorship from them and i
                            cleared all my backlogs at one go .
                        </p>
                    </div>
                    <div className={homePageClasses.student_review}>
                        <div className={homePageClasses.student_detail}>
                            <img src={profile} alt="profilepic" />
                            <p>Adarsh</p>
                        </div>
                        {/* <img src={pic} alt="img" /> */}
                        <p>
                            Hi , my name is Adarsh , i am studying 4th sem
                            computer science engineering . I faced a lot of
                            difficulty with DAA subject , i came to know about
                            veteach from my friend , i bought the DDA subject
                            from the app , the course content is very easy to
                            understand and as per the university syllabus also
                            the entire subject is completed in just 12 hrs
                        </p>
                    </div>
                </Slider>
            </div>
        );
    }
}

export default Services;
