import create from "zustand";

const Store = create((set) => ({
    mobile: "",
    setMobile: (mobile) => set((state) => ({ ...state, mobile: mobile })),
    subjects: [],
    setSubjects: (subjects) =>
        set((state) => ({ ...state, subjects: subjects })),
}));

export default Store;
