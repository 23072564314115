// 662932143
import React, { useState, useEffect } from "react";
import NavBar from "../HomePage/Navbar";
import Footer from "../HomePage/footer";
import referClasses from "./userdetails.module.css";
import { useNavigate } from "react-router-dom";
import Usernav from "./Usernav";
import refer from "../Assets/images/refer.svg";
import referbg from "../Assets/images/referbg.svg";
import copy from "../Assets/images/copy.svg";
import facebook from "../Assets/images/Facebook.svg";
import gmail from "../Assets/images/Gmail.svg";
import Telegram from "../Assets/images/Telegram.svg";
import whatsapp from "../Assets/images/WhatsApp.svg";
import { BsWhatsapp } from "react-icons/bs";
function Refer() {
    const Navigate = useNavigate();
    const [userDetails, setUserDetails] = useState();
    const [copytext, setcopytext] = useState(false);
    useEffect(() => {
        if (sessionStorage.token !== null) {
            setUserDetails(JSON.parse(sessionStorage.getItem("userdetails")));
        }
    }, []);
    return (
        <div className={referClasses["refer-wrapper"]}>
            <NavBar isActive="userdata" />
            <div className={referClasses["refer-content"]}>
                <div className={referClasses["refer-content-leftside"]}>
                    <Usernav isActive="refer" />
                </div>
                <div className={referClasses["refer-content-rightside"]}>
                    <h3>Refer & Earn</h3>
                    <div className={referClasses["refer-code"]}>
                        <img src={refer} alt="refer" />
                        <img
                            className={referClasses["refer-bg"]}
                            src={referbg}
                            alt="referbg"
                        />
                        <div className={referClasses["invite-code"]}>
                            <p>{userDetails?.MyReferrelCode}</p>
                            <button
                                onClick={() => {
                                    setcopytext(true);
                                    navigator.clipboard.writeText(
                                        userDetails?.MyReferrelCode
                                    );
                                    setTimeout(() => {
                                        setcopytext(false);
                                    }, 1000);
                                }}>
                                <img
                                    src={copy}
                                    alt="copy"
                                    className={referClasses["copy-icon"]}
                                />
                                {copytext && (
                                    <h5>Referral code copied to Clipboard</h5>
                                )}
                            </button>
                        </div>
                        <div className={referClasses["social-icons"]}>
                            <img src={whatsapp} alt="social" />
                            <img src={facebook} alt="social" />
                            <img src={Telegram} alt="social" />
                            <img src={gmail} alt="social" />
                        </div>
                        <button>Invite</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Refer;
