import React, { useEffect, useState } from "react";
import { Services } from "../services";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../HomePage/Navbar";
import Courseclasses from "./course.module.css";
import ReactPlayer from "react-player";
import Spinner from "../spinner";
import { Rating } from "react-simple-star-rating";
import TopicsVideos from "./topicsVideos";
function Coursevideos() {
    const { id } = useParams();
    const Navigate = useNavigate();
    const [spinner, setSpinner] = useState(true);
    const [univdata, setunivdata] = useState([]);
    const [syllabus, setsyllabus] = useState([]);
    const [subjectContent, setSubjectContent] = useState([]);
    const [unitcontent, setunitContent] = useState([]);
    const [unitchange, setunitchange] = useState();
    const [videoid, setvideoid] = useState();
    useEffect(() => {
        console.log("id", id);
        Services.subjectContentBySubjectId(id)

            .then((res) => {
                console.log(res);
                if (res.Status === 1 && res?.SubjectContents.length > 0) {
                    setunivdata(
                        JSON.parse(sessionStorage.getItem("selectedSubject"))
                    );
                    setSubjectContent(res.SubjectContents);
                    setunitContent(res.SubjectContents[0]);
                    setsyllabus(res.Syllabus);
                    setvideoid(res.SubjectContents[0]?.Topics[0]);
                    setSpinner(false);
                    console.log(res.SubjectContents[0]?.Topics[0]);
                } else if (
                    res.Status === 1 &&
                    res?.SubjectContents.length == 0
                ) {
                    alert("no Data found please check later");
                    Navigate(-1);
                } else {
                    alert("something went wrong please try again");
                    Navigate("/");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                Navigate("/");
            });
    }, []);
    return (
        <>
            <Navbar isActive={"Course"} />
            {spinner ? (
                <Spinner />
            ) : (
                <div className={Courseclasses.videoContentWrapper}>
                    <div className={Courseclasses["videocontent-Leftside"]}>
                        <div className={Courseclasses.videoplayer}>
                            <p>Please Subscribe to Watch full Video</p>
                            <div className={Courseclasses.video_player}>
                                <ReactPlayer
                                    width={"100%"}
                                    height={"100%"}
                                    url={`https://vimeo.com/${videoid.VideoTrailID}`}
                                    controls={true}
                                />
                            </div>
                        </div>
                        <div className={Courseclasses["video-Data"]}>
                            <h3>{videoid.TopicName}</h3>

                            <h5>
                                {`${univdata.UniversityName} >>${univdata.Regulation} >> ${univdata.Specialization}>> ${univdata.SubjectName}`}{" "}
                            </h5>
                            <div className={Courseclasses.videoRating}>
                                <p>{univdata.RatingCount}</p>
                                <Rating
                                    ratingValue={
                                        (Number(univdata.Rating) * 100) / 5
                                    }
                                    initialValue={
                                        (Number(univdata.Rating) * 100) / 5
                                    }
                                    size={20}
                                    readonly={true}
                                    fillColor={"#eb8a2f"}
                                />
                            </div>
                            <div className={Courseclasses.purchase}>
                                <p>
                                    To Get Complete Notes and video Lessons
                                    Purchase <span>{univdata.SubjectName}</span>{" "}
                                    For Just ₹ {univdata.DiscountPrice}{" "}
                                    <del>{univdata.Price}</del>
                                </p>
                                <div className={Courseclasses["buy-btns"]}>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.vasthi.veteach"
                                        target={"_blank"}
                                        rel="noreferrer">
                                        Buy Now in App
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Courseclasses["videocontent-rightside"]}>
                        <TopicsVideos
                            syllabus={syllabus}
                            setvideoid={setvideoid}
                            subjectContent={subjectContent}
                            unitcontent={unitcontent}
                            setunitContent={setunitContent}
                            setSpinner={setSpinner}
                            unitchange={unitchange}
                            setunitchange={setunitchange}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Coursevideos;
