import React, { useEffect, useState } from "react";
import SubscriptionClasses from "./userdetails.module.css";
import user from "../Assets/images/user.svg";
import { NavLink } from "react-router-dom";
import { BsCollectionPlayFill, BsFillBookmarkDashFill } from "react-icons/bs";
import { IoCall, IoLogOut } from "react-icons/io5";
import { FiLink } from "react-icons/fi";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { BsShieldExclamation } from "react-icons/bs";
import { AiOutlineTransaction } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function Usernav(props) {
    const token = sessionStorage.getItem("token");
    const [userdetails, setuserdetails] = useState("");
    const [profilepic, setprofilepic] = useState(user);
    const Navigate = useNavigate();
    const isActive = props.isActive;
    useEffect(() => {
        if (token !== null) {
            const data = JSON.parse(sessionStorage.getItem("userdetails"));
            setuserdetails(data);

            setprofilepic(data?.ProfilePic);
        }
    }, []);
    const logout = () => {
        if (window.confirm("are you Sure you want to signOut")) {
            sessionStorage.clear();
            Navigate("/");
        }
    };
    return (
        <>
            <div className={SubscriptionClasses["nav-header"]}>
                <img src={profilepic} alt="user" />
                <p>{userdetails?.FirstName}</p>
                <button
                    onClick={() => {
                        Navigate("/edit-profile");
                    }}>
                    Edit Profile
                </button>
            </div>
            <div className={SubscriptionClasses["user-navbar"]}>
                <NavLink
                    className={
                        isActive === "subscriptions"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/subscriptions">
                    <BsCollectionPlayFill
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> My Subscriptions</span>
                </NavLink>
                {/* <NavLink
                    className={
                        isActive === "library"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/library">
                    <BsFillBookmarkDashFill
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> My Library</span>
                </NavLink> */}
                {/* <NavLink
                    className={
                        isActive === "support"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/support">
                    <IoCall
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Support</span>
                </NavLink> */}
                {/* <NavLink
                    className={
                        isActive === "refer"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/refer">
                    <FiLink
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Refer & Earn</span>
                </NavLink> */}
                <NavLink
                    className={
                        isActive === "feedback"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/Feedback">
                    <MdOutlineSpeakerNotes
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Feedback</span>
                </NavLink>
                {/* <NavLink
                    className={
                        isActive === "transactions"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/transactions">
                    <AiOutlineTransaction
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Transactions</span>
                </NavLink>
                <NavLink
                    className={
                        isActive === "privacy policy"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/PrivacyPolicy">
                    <BsShieldExclamation
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Privacy Policy</span>
                </NavLink> */}
                {/* <NavLink
                    className={
                        isActive === "Terms"
                            ? SubscriptionClasses["usernav-active"]
                            : SubscriptionClasses["usernav-inactive"]
                    }
                    to="/Terms">
                    <RiErrorWarningFill
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <span> Terms of Use</span>
                </NavLink> */}
                <button onClick={logout}>
                    <IoLogOut
                        className={SubscriptionClasses["nav-icon"]}
                        color="#EFE690"
                    />
                    <p>Logout</p>
                </button>
            </div>
        </>
    );
}

export default Usernav;
