import React, { useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import ReactLoading from "react-loading";
import signup from "../Assets/booksignup.png";
import { Services } from "../services";

function LoginModal(props) {
    const [loading, setloading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [showotp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    // const [load,]

    //setloading add this to disable button
    const signupHandler = (e) => {
        e.preventDefault();
        setError("");
        if (showotp) {
            // bookingsignupVerify
            if (otp.length != 4) {
                setError("invalid OTP");
                return;
            }

            let body = JSON.stringify({ OTP: otp, MobileNumber: mobileNumber });
            Services.bookingsignupVerify(body, "")
                .then((response) => {
                    console.log(response);
                    if (response.Status == 1) {
                        localStorage.setItem("booksessionData", 1);
                        props.onCloseModal();
                        props.setOpen(true);
                    } else {
                        setError(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("some thing went wrong please try again");
                });
        } else {
            if (mobileNumber.length != 10) {
                setError("Enter a valid mobile Number ");
                return;
            }
            //bookingsignup
            let body = JSON.stringify({ MobileNumber: mobileNumber });
            Services.bookingsignup(body, "")
                .then((response) => {
                    console.log(response);
                    if (response.Status == 1) {
                        if (response.IsVerified == 1) {
                            setError(response.Message);
                            localStorage.setItem("booksessionData", 1);
                            setTimeout(function () {
                                props.onCloseModal();
                                props.setOpen(true);
                                // Do something after 5 seconds
                            }, 2000);
                        } else {
                            setShowOtp(true);
                        }
                    } else {
                        setError(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("some thing went wrong please try again");
                });
        }
    };
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onCloseModal}
                center
                closeOnOverlayClick={false}
                showCloseIcon={false}
                styles={{
                    overlay: {
                        background: "black",
                        opacity: "0.7",
                        padding: 0,
                    },
                }}>
                <div className="books-signup-container">
                    <img src={signup} alt="signup" />
                    <form action="">
                        <h3>Register Yourself</h3>
                        {showotp ? (
                            <p>Enter OTP </p>
                        ) : (
                            <p>Enter your 10 digit phone Number</p>
                        )}
                        {!showotp ? (
                            <div>
                                <input
                                    placeholder="Enter mobile number"
                                    type="number"
                                    autoFocus
                                    value={mobileNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setMobileNumber(e.target.value);
                                        }
                                    }}
                                />
                                {/* <p>An OTP will be Sent on this number</p> */}
                                {error && (
                                    <p
                                        style={{
                                            color: "red",
                                            fontSize: "0.7rem",
                                        }}>
                                        {error}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <div>
                                <input
                                    value={otp}
                                    placeholder="OTP"
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                                {error && (
                                    <p
                                        style={{
                                            color: "red",
                                            fontSize: "0.7rem",
                                        }}>
                                        {error}
                                    </p>
                                )}
                            </div>
                        )}

                        <button onClick={signupHandler} disabled={loading}>
                            Sign up
                        </button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default LoginModal;
