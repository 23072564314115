import React, { useEffect, useState } from "react";
import HomepageClasses from "./homepage.module.css";
import Circle from "../Assets/images/circle.svg";
import Modal from "./modal";
function BrowsebyCategories() {
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/user/web/courses
        `)
            .then((res) => res.json())
            .then((response) => {
                if (response.Status === 1) {
                    setCategories(response.Courses);
                } else {
                    alert("some thing went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    }, []);
    return (
        <>
            {modal && <Modal setmodal={setModal} />}
            <div className={HomepageClasses.BrowsebyCategories}>
                {/* <div className={HomepageClasses.mentor}>
                    <h3>
                        Check Out Our <br /> Mentorship List
                    </h3>
                    <button>Mentor List</button>
                </div> */}
                <header>
                    <h3>
                        Browse by Categories <img src={Circle} alt="circle" />
                    </h3>
                </header>
                <div className={HomepageClasses.subjects}>
                    {categories?.length > 0 &&
                        categories?.map((category) => {
                            return (
                                <button
                                    className={HomepageClasses.subject}
                                    onClick={() => {
                                        setModal(true);
                                    }}>
                                    <div
                                        className={HomepageClasses.subjectIcon}>
                                        {" "}
                                        <img
                                            src={category.Image}
                                            alt="subject"
                                        />
                                    </div>
                                    <h3>{category.CourseName}</h3>
                                </button>
                            );
                        })}
                </div>
                {/* <button>Load More</button> */}
            </div>
        </>
    );
}

export default BrowsebyCategories;
