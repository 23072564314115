// 662932143
import React, { useState, useEffect } from "react";
import NavBar from "../HomePage/Navbar";
import Footer from "../HomePage/footer";
import referClasses from "./userdetails.module.css";
import { useNavigate } from "react-router-dom";
import Usernav from "./Usernav";
import { useForm } from "react-hook-form";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail, MdPhoneIphone } from "react-icons/md";

function Refer() {
    const Navigate = useNavigate();
    const [userDetails, setUserDetails] = useState();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (sessionStorage.token !== null) {
            const userdata = JSON.parse(sessionStorage.getItem("userdetails"));
            setUserDetails(userdata);
            reset({
                MobileNumber: userdata?.MobileNumber,
                Name: userdata?.FirstName,
                EmailID: userdata?.EmailID,
            });
        }
    }, []);
    const onSubmit = (data) => console.log(data);
    return (
        <div className={referClasses["support-wrapper"]}>
            <NavBar isActive="userdata" />
            <div className={referClasses["support-content"]}>
                <div className={referClasses["support-content-leftside"]}>
                    <Usernav isActive="feedback" />
                </div>
                <div className={referClasses["support-content-rightside"]}>
                    <h3>Support</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                className={
                                    errors?.Name && referClasses["input-error"]
                                }
                                type="text"
                                placeholder="Enter Name"
                                {...register("Name", {
                                    required: "Enter Name",
                                    maxLength: 20,
                                })}
                            />
                            {errors?.Name && (
                                <p style={{ color: "red" }}>
                                    {errors?.Name?.Message}
                                </p>
                            )}
                            <FaUserAlt
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                className={
                                    errors?.MobileNumber &&
                                    referClasses["input-error"]
                                }
                                type="number"
                                placeholder="Enter Mobile Number"
                                {...register("MobileNumber", {
                                    required: "Enter Mobile Number",
                                    maxLength: 10,
                                })}
                            />
                            {errors?.MobileNumber && (
                                <p style={{ color: "red" }}>
                                    {errors?.MobileNumber?.Message}
                                </p>
                            )}
                            <MdPhoneIphone
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <input
                                type="email"
                                className={
                                    errors?.EmailID &&
                                    referClasses["input-error"]
                                }
                                placeholder="Enter Email"
                                {...register("EmailID", {
                                    required: "Enter Email",
                                })}
                            />
                            {errors?.EmailID && (
                                <p style={{ color: "red" }}>
                                    {errors?.EmailID?.Message}
                                </p>
                            )}
                            <MdEmail
                                className={referClasses["form-icon"]}
                                size={24}
                                color="#EFE690"
                            />
                        </div>
                        <div className={referClasses["support-formControl"]}>
                            <textarea
                                placeholder="Enter Message"
                                rows={6}
                                {...register("Message", {
                                    required: "Enter Message",
                                })}></textarea>
                            {errors?.Message && (
                                <p style={{ color: "red" }}>
                                    {errors?.Message?.Message}
                                </p>
                            )}
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Refer;
