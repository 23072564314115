import React from "react";
import FooterClasses from "./course.module.css";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { NavLink } from "react-router-dom";
function Footer() {
    return (
        <div className={FooterClasses["footer-wrapper"]}>
            <a
                href="https://play.google.com/store/apps/details?id=com.vasthi.veteach"
                target={"_blank"}
                rel="noreferrer">
                <IoLogoGooglePlaystore
                    className="playstore-icon"
                    size={32}
                    color="#FFFFFF"
                />
                <div>
                    <p>Available on</p>
                    <h5>Google Play</h5>
                </div>
            </a>
            <h4>Learn Today</h4>
            <p>PAY ONCE AND GET ALL VETEACH ACCESS, FREE LIFETIME UPDATES.</p>
            <div className={FooterClasses["footnav"]}>
                <NavLink to="/contactUs">Contact-us</NavLink>
                <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
                <NavLink to="/Terms">Terms and Conditions</NavLink>
            </div>
        </div>
    );
}

export default Footer;
