import { NavLink, useNavigate } from "react-router-dom";
import HomePageClasses from "./homepage.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import React, { useEffect, useState } from "react";
import logo from "../Assets/images/logo.svg";
import Modal from "./modal";
const Navbar = (props) => {
    const [modal, setModal] = useState(false);
    const Navigate = useNavigate();
    const [showLinks, setShowLinks] = useState(false);
    const token = sessionStorage.getItem("token");
    const [userdetails, setuserdetails] = useState("");
    const handleResize = () => {
        if (window.innerWidth > 1360) {
            setShowLinks(false);
        }
    };
    useEffect(() => {
        if (token !== null) {
            setuserdetails(JSON.parse(sessionStorage.getItem("userdetails")));
        }
    }, []);
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    const home = () => {
        Navigate("/");
    };
    const coursehandler = () => {
        setModal(true);
    };
    return (
        <div className={HomePageClasses.Navbar}>
            {modal && <Modal setmodal={setModal} />}
            <div className={HomePageClasses.leftside}>
                <div
                    className={HomePageClasses.lefside_imgwrapper}
                    onClick={home}>
                    <img src={logo} alt="logo" />
                </div>
            </div>
            <div className={HomePageClasses.rightside}>
                <div
                    className={`${HomePageClasses.links} `}
                    id={showLinks ? HomePageClasses.hidden : ""}>
                    <NavLink
                        className={({ isActive }) =>
                            props.isActive == "home"
                                ? HomePageClasses.active
                                : HomePageClasses.inactive
                        }
                        to="/">
                        Home
                    </NavLink>
                    {/* 
                    <NavLink
                        to="/courses"
                        className={({ isActive }) =>
                            props.isActive == "Courses"
                                ? HomePageClasses.active
                                : HomePageClasses.inactive
                        }>
                        Courses
                    </NavLink> */}
                    <button
                        className={
                            props.isActive == "Courses"
                                ? HomePageClasses.buttonactive
                                : HomePageClasses.buttoninactive
                        }
                        id={showLinks ? HomePageClasses.hidebutton : ""}
                        onClick={coursehandler}>
                        Courses
                    </button>
                    <NavLink
                        to="/About_us"
                        className={({ isActive }) =>
                            props.isActive == "about"
                                ? HomePageClasses.active
                                : HomePageClasses.inactive
                        }>
                        About
                    </NavLink>
                    <NavLink
                        to="/contactUs"
                        className={({ isActive }) =>
                            isActive
                                ? HomePageClasses.active
                                : HomePageClasses.inactive
                        }>
                        Contact
                    </NavLink>
                    {token !== null ? (
                        <NavLink
                            to="/subscriptions"
                            className={({ isActive }) =>
                                props.isActive == "userdata"
                                    ? HomePageClasses.active
                                    : HomePageClasses.inactive
                            }>
                            {userdetails?.FirstName}
                        </NavLink>
                    ) : (
                        <div
                            className={HomePageClasses.buttonwrapper}
                            id={
                                showLinks ? HomePageClasses.hidebuttonwrap : ""
                            }>
                            <button
                                className={HomePageClasses.Login}
                                id={showLinks ? HomePageClasses.hidebutton : ""}
                                onClick={() => {
                                    Navigate("/login");
                                }}>
                                Log in
                            </button>
                            <button
                                className={HomePageClasses.Signup}
                                id={showLinks ? HomePageClasses.hidebutton : ""}
                                onClick={() => {
                                    Navigate("/signup");
                                }}>
                                Sign up
                            </button>
                        </div>
                    )}
                </div>
                <button
                    className={HomePageClasses.button}
                    onClick={() => {
                        setShowLinks(!showLinks);
                    }}>
                    <GiHamburgerMenu
                        size={30}
                        className={HomePageClasses.icon}
                    />
                </button>
            </div>
        </div>
    );
};

export default Navbar;
