import React, { useState } from "react";
import loginClasses from "./login.module.css";
import { useForm } from "react-hook-form";
import { MdPhoneIphone } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
function Signup() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };
    const formdata = watch();
    return (
        <div className={loginClasses.loginWrapper}>
            <div
                className={`${loginClasses["login-leftside"]} ${loginClasses["signup-leftside"]} ${loginClasses["signup-details"]}`}>
                <div className={loginClasses["login-header"]}>
                    <h3>Sign UP</h3>
                    <p>Please Sign UP to Continue</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Your Name</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                className={
                                    errors?.Name && loginClasses["input-error"]
                                }
                                placeholder="Enter Your Name"
                                {...register("Name", {
                                    required: "Please enter your Name",
                                })}
                            />
                            {errors?.Name && (
                                <p className={loginClasses["error"]}>
                                    {errors?.Name?.message}
                                </p>
                            )}
                            <FaUserAlt
                                className={loginClasses["login-icon-name"]}
                                color="#EFE690"
                                size={22}
                            />
                        </div>
                    </div>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Mobile Number</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                className={
                                    errors?.MobileNumber &&
                                    loginClasses["input-error"]
                                }
                                placeholder="Enter Mobile Number"
                                type="number"
                                {...register("MobileNumber", {
                                    required: "Please enter Your Mobile Number",
                                    minLength: {
                                        value: 10,
                                        message: "Enter 10 digit Mobile number",
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Enter 10 digit Mobile number",
                                    },
                                })}
                            />
                            {errors?.MobileNumber && (
                                <p className={loginClasses["error"]}>
                                    {errors?.MobileNumber?.message}
                                </p>
                            )}

                            <MdPhoneIphone
                                className={loginClasses["login-icon"]}
                                color="#EFE690"
                                size={30}
                            />
                        </div>
                    </div>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Email</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                type={"email"}
                                className={
                                    errors?.Email && loginClasses["input-error"]
                                }
                                placeholder="please Enter Your Email ID"
                                {...register("Email", {
                                    required: "Please enter your Email",
                                })}
                            />
                            {errors?.Email && (
                                <p className={loginClasses["error"]}>
                                    {errors?.Email?.message}
                                </p>
                            )}
                            <MdEmail
                                className={loginClasses["login-icon-name"]}
                                color="#EFE690"
                                size={26}
                            />
                        </div>
                    </div>
                    <input type={"submit"} value="Submit" />
                </form>
            </div>
            {/* <div className={loginClasses["login-rightside"]}>
                <img src={login_bg} alt="login" />
            </div> */}
        </div>
    );
}

export default Signup;
