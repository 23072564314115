import React, { useEffect, useState } from "react";
import CourseClasses from "./course.module.css";
import Navbar from "../HomePage/Navbar";
import btech from "../Assets/images/Courses/btech_image.png";
import Home from "../Assets/images/Courses/main_home.png";
import AllCourse from "./AllCourses";
import CoursesubNav from "./CoursesubNav";
import mentor_img from "../Assets/images/Courses/mentor_img.png";
import { Services } from "../services";
import { useForm } from "react-hook-form";
import Footer from "./courseFooter";
function Courses() {
    const [buttonid, setButtonId] = useState("1");
    const [universities, setUniversities] = useState([]);
    const [regulation, setRegulations] = useState([]);
    const [branch, setBranch] = useState([]);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        register: register2,
        watch: watch2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm();
    const formData = watch();
    const formData2 = watch2();
    const getregulation = (id) => {
        Services.getRegulationsByUnivId(id)
            .then((res) => {
                if (res?.Status === 1) {
                    setRegulations(res?.Regulations);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    };
    useEffect(() => {
        // get alll universities drop down menu
        Services.getAllUniversities()
            .then((response) => {
                if (response.Status === 1) {
                    setUniversities(response?.Universities);
                    getregulation(response?.Universities[0].UniversityID);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
        // get all branches
        Services.getBranch()
            .then((res) => {
                if (res?.Status === 1) {
                    setBranch(res?.Courses);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    }, []);

    const universityHandler = (e) => {
        let id = e.target.value;
        getregulation(id);
        // fetch regulation by univ id
    };
    const RegulationClickHandler = () => {
        if (formData.University === "") {
            alert("please select university");
        }
    };
    const RegulationClickHandlertwo = () => {
        if (formData2.University === "") {
            alert("please select university");
        }
    };

    const onSubmit = (data) => console.log(data);
    const onSubmit2 = (data) => console.log(data);
    return (
        <div className={CourseClasses["CourseWrapper"]}>
            <Navbar isActive={"Courses"} />
            {/* <div className={CourseClasses["Course-content"]}>
                <img src={btech} alt="btech" />
                <p>India’s Smart Learning Platform…</p>
                <div className={CourseClasses["scroller-card"]}>
                    <div className={CourseClasses["scroller"]}>
                        <span>
                            Live Classes
                            <br />
                            Previous Papers
                            <br />
                            Mentor To Student
                            <br />
                            All Courses Videos
                        </span>
                    </div>
                </div>
                <div className={CourseClasses.bg_image}>
                    <img src={Home} alt="background" />
                </div>
                <button>CHANGE COURSE</button>
                <div className={CourseClasses["heading"]}>
                    <h3>AI Base B.Tech Tuitions</h3>
                    <p>ECE CSE EEE CIVIL MECH IOT AI & DS IT AI AI & ML</p>
                </div>
            </div> */}
            <div className={CourseClasses["all-subjects"]}>
                <h3>YOUR SEMESTER SUBJECTS</h3>
                <AllCourse />
            </div>
            {/* <CoursesubNav buttonid={buttonid} setButtonId={setButtonId} />
            {buttonid === "1" && (
                <div className={CourseClasses["mentorship-wrapper"]}>
                    <div className={CourseClasses["mentorship"]}>
                        <img src={mentor_img} alt="mentorship" />
                        <div className={CourseClasses["mentor-button"]}>
                            <p>
                                Try Our Very Own <br />
                                <span>Mentorship Program</span>
                            </p>
                            <button>Join Now</button>
                        </div>
                    </div>
                </div>
            )}
            {buttonid === "2" && (
                <div className={CourseClasses["questionpaper-wrapper"]}>
                    <div className={CourseClasses["questionPaper-header"]}>
                        <h3>BTECH PREVIOUS PAPERS</h3>
                        <p>
                            Free Question Papers for all Branches-
                            Regular/supplementary
                        </p>
                    </div>
                    <div className={CourseClasses["question-paper-form"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={
                                    CourseClasses["question-paper-control"]
                                }>
                                <select
                                    className={`${
                                        errors?.univeristy &&
                                        CourseClasses["paper-error"]
                                    }`}
                                    {...register("University", {
                                        required: true,
                                        onChange: (e) => {
                                            universityHandler(e);
                                        },
                                    })}>
                                    {universities?.map((university) => (
                                        <option
                                            value={university?.UniversityID}
                                            key={university?.UniversityID}>
                                            {university?.UniversityName}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className={`${
                                        errors?.Regulation &&
                                        CourseClasses["paper-error"]
                                    }`}
                                    onClick={RegulationClickHandler}
                                    defaultValue=""
                                    {...register("Regulation", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Regulation
                                    </option>
                                    {regulation?.map((regulation) => (
                                        <option
                                            value={regulation?.RegulationID}
                                            key={regulation?.RegulationID}>
                                            {regulation?.Regulation}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className={
                                        errors?.Branch &&
                                        CourseClasses["paper-error"]
                                    }
                                    defaultValue=""
                                    {...register("Branch", { required: true })}>
                                    <option value="" disabled>
                                        Select Branch
                                    </option>
                                    {branch.map((branch) => (
                                        <option
                                            data-label={branch.CourseName}
                                            key={branch?.CourseID}
                                            value={branch?.CourseID}>
                                            {branch.CourseName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit">SUBMIT</button>
                        </form>
                    </div>
                </div>
            )}

            {buttonid === "3" && (
                <div className={CourseClasses["questionpaper-wrapper"]}>
                    <div className={CourseClasses["questionPaper-header"]}>
                        <h3>SYLLABUS</h3>
                        <p>Download All Syllabus</p>
                    </div>
                    <div className={CourseClasses["question-paper-form"]}>
                        <form onSubmit={handleSubmit2(onSubmit2)}>
                            <div
                                className={
                                    CourseClasses["question-paper-control"]
                                }>
                                <select
                                    className={`${
                                        errors2?.univeristy &&
                                        CourseClasses["paper-error"]
                                    }`}
                                    {...register2("University", {
                                        required: true,
                                        onChange: (e) => {
                                            universityHandler(e);
                                        },
                                    })}>
                                    {universities?.map((university) => (
                                        <option
                                            value={university?.UniversityID}
                                            key={university?.UniversityID}>
                                            {university?.UniversityName}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className={`${
                                        errors2?.Regulation &&
                                        CourseClasses["paper-error"]
                                    }`}
                                    onClick={RegulationClickHandlertwo}
                                    defaultValue=""
                                    {...register2("Regulation", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Regulation
                                    </option>
                                    {regulation?.map((regulation) => (
                                        <option
                                            value={regulation?.RegulationID}
                                            key={regulation?.RegulationID}>
                                            {regulation?.Regulation}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className={
                                        errors2?.Branch &&
                                        CourseClasses["paper-error"]
                                    }
                                    defaultValue=""
                                    {...register2("Branch", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Branch
                                    </option>
                                    {branch.map((branch) => (
                                        <option
                                            data-label={branch.CourseName}
                                            key={branch?.CourseID}
                                            value={branch?.CourseID}>
                                            {branch.CourseName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                className={CourseClasses["submit"]}
                                type="submit">
                                Download
                            </button>
                        </form>
                    </div>
                </div>
            )} */}
            <Footer />
        </div>
    );
}

export default Courses;
