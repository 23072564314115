import React from "react";
import HomePageClasses from "./homepage.module.css";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function About() {
    return (
        <div className={HomePageClasses.AboutWrapper}>
            <h3>Start Learning From India’s Smart Learning Platform</h3>
            <p>
                Simplified and straight to the point course created for you.
                maximaze your results
            </p>
            <a
                href="https://play.google.com/store/apps/details?id=com.vasthi.veteach"
                target={"_blank"}
                rel="noreferrer">
                <IoLogoGooglePlaystore
                    className="playstore-icon"
                    size={32}
                    color="#FFFFFF"
                />
                <div>
                    <p>Available on</p>
                    <h5>Google Play</h5>
                </div>
            </a>
        </div>
    );
}

export default About;
