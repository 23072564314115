import React from "react";
import HomePageClasses from "./homepage.module.css";
import Circle from "../Assets/images/circle.svg";
function WhyChooseUs() {
    return (
        <div className={HomePageClasses.why_Choose_us_Wrapper}>
            <header>
                <h3>
                    Why choose us <img src={Circle} alt="circle" />
                </h3>
            </header>
            <div className={HomePageClasses["why-choose-us-cards"]}>
                <div className={HomePageClasses["why-choose-us-card"]}>
                    <h1>01</h1>
                    <h3>Online Classes</h3>
                    <p>
                        It is Long established fact for that a reader will
                        distracted by the readable{" "}
                    </p>
                </div>
                <div className={HomePageClasses["why-choose-us-card"]}>
                    <h1>02</h1>
                    <h3>Expert Instructor</h3>
                    <p>
                        It is Long established fact for that a reader will
                        distracted by the readable{" "}
                    </p>
                </div>
                <div
                    className={`${HomePageClasses["why-choose-us-card"]} ${HomePageClasses.lastCard}`}>
                    <h1>03</h1>
                    <h3>24/7 Live support</h3>
                    <p>
                        It is Long established fact for that a reader will
                        distracted by the readable{" "}
                    </p>
                    <img src={Circle} alt="circle" />
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;
