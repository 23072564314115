import React, { useEffect, useState } from "react";
import AboutClasses from "./About.module.css";
import Navbar from "../HomePage/Navbar";
import Footer from "../courses/courseFooter";
function Courses() {
    return (
        <div className={AboutClasses["about-wrapper"]}>
            <Navbar isActive={"about"} />
            <div className={AboutClasses["about-content"]}>
                <h3>ABOUT US</h3>
                <p>
                    Diminishing quality of engineering education but at the same
                    time, more and more students are aspiring to join B.Tech,
                    This problem provides a good opportunity for an online
                    platform which can help btech students in university exams
                    and at the same time provide skills that can help them in
                    the job market. The problem with Btech education in india is
                    , mushrooming of colleges substandard colleges also there is
                    a non existence linkage with the industry and industry
                    laments that the skills that are, being taught in the
                    colleges are not sufficient for the job market .
                </p>
                <p>
                    These conditions lead to a state where ,93% of B.Tech
                    graduates are not employable . Btech students have class
                    room as the main source for learning . There wasn’t any
                    specific digital platform for students that can provide
                    learning experience any where any time. Also B.Tech syllabus
                    is vast and complicated, majority of the colleges have
                    limited resources both in term of faculty and also
                    infrastructure and this is a big challenge for college
                    managements.
                </p>
                <p>
                    {" "}
                    The Pass % of B.Tech students is not more than 40% and the
                    attention given by majority of B.Tech colleges towards
                    backlog students is little . A btech student is on his own
                    when he has backlogs , he has to prepare on his own and
                    attend exams . Veteach is india’s first digital platform
                    especially designed btech students to help them clear
                    university exams and at the same time act as a bridge
                    between students and industry .
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default Courses;
