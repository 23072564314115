import React, { useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import ReactLoading from "react-loading";
import signup from "../Assets/booksignup.png";
import Playstore from "../Assets/playstore.png";
import AppStore from "../Assets/appstore.png";
import { Services } from "../services";

function LoginModal(props) {
    return (
        <div>
            <Modal
                open={props.open}
                // onClose={props.setOpen(false)}
                center
                closeOnOverlayClick={false}
                showCloseIcon={false}
                styles={{
                    overlay: {
                        background: "black",
                        opacity: "0.7",
                        padding: 0,
                    },
                }}>
                <div className="books-signup-container">
                    <img src={signup} alt="signup" />
                    <div className="download-popup-btns">
                        <button
                            onClick={() =>
                                (window.location.href =
                                    "https://play.google.com/store/apps/details?id=com.vasthi.veteach")
                            }>
                            <img src={Playstore} />
                            <p>
                                {" "}
                                GET IT ON <br />
                                <span>Google Play</span>
                            </p>
                        </button>
                        <button
                            onClick={() =>
                                (window.location.href =
                                    "https://apps.apple.com/in/app/veteach-elearning-platform/id1641883623")
                            }>
                            <img src={AppStore} />{" "}
                            <p>
                                {" "}
                                Download on the <br />
                                <span> App Store</span>
                            </p>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default LoginModal;
