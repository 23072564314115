import React, { useState } from "react";
import CourseClasses from "../courses/course.module.css";
import Navbar from "../HomePage/Navbar";
import Footer from "../courses/courseFooter";
import ContactClasses from "./contactus.module.css";
import { Services } from "../services";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Spinner from "../spinner";
function ContactUs() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [loader, setLoader] = useState(false);
    const onSubmit = (data) => {
        setLoader(true);
        const token = {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        };
        let formDataJson = JSON.stringify({ ...data });
        Services.support(formDataJson, token)
            .then((response) => {
                console.log(response);
                setLoader(false);
                if (response.Status == 1) {
                    reset();

                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    alert(response.Message);
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("some thing went wrong please try again");
            });
    };
    return (
        <div className={CourseClasses["CourseWrapper"]}>
            <Navbar />

            <div className={ContactClasses.contactWrapper}>
                <div className={ContactClasses["heading"]}>
                    <h1>CONTACT US</h1>
                    <p>Feel free to contact us at any time.</p>
                </div>
                <div className={ContactClasses["contact-details"]}>
                    <div className={ContactClasses["map"]}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25917.88674753904!2d78.3955525982285!3d17.483389483392582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9198c6f867df%3A0x8839f2f1b2622082!2sveteach.in!5e0!3m2!1sen!2sin!4v1657099171214!5m2!1sen!2sin"
                            width="500"
                            height="300"
                            style={{ border: 0 }}
                            // style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div className={ContactClasses["address"]}>
                            <h4>Hyderabad</h4>
                            <h3>VETEACH.IN</h3>
                            <p>
                                Manjeera Trinity Corporate, <br />
                                Plot No-112, Survey No:1050,
                                <br /> JNTU, Hitech city Rd, KPHB Colony <br />
                                Kukatpally, Hyderabad-500072 <br />
                                Phone : +91 7382708685
                                <br /> Web : www.veteach.in
                                <br />
                                Email: info@veteach.in
                            </p>
                        </div>
                    </div>
                    {loader && <Spinner />}
                    <div className={ContactClasses["contact-us"]}>
                        <h3>Contact Form</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={ContactClasses["form-control"]}>
                                <label htmlFor="Name">Name</label>
                                <input
                                    type={"text"}
                                    placeholder="Your name"
                                    {...register("Name", {
                                        required: "Name is Required",
                                    })}
                                />

                                {errors?.Name && (
                                    <p className={ContactClasses["error"]}>
                                        {errors?.Name?.message}
                                    </p>
                                )}
                            </div>
                            <div className={ContactClasses["form-control"]}>
                                <label htmlFor="EmailID">Email</label>
                                <input
                                    type={"email"}
                                    placeholder="Your email"
                                    {...register("EmailID", {
                                        required: "Email  is Required",
                                    })}
                                />

                                {errors?.EmailID && (
                                    <p className={ContactClasses["error"]}>
                                        {errors?.EmailID?.message}
                                    </p>
                                )}
                            </div>
                            <div className={ContactClasses["form-control"]}>
                                <label htmlFor="phone">Phone</label>
                                <input
                                    type={"number"}
                                    placeholder="Your phone"
                                    {...register("MobileNumber", {
                                        required: "phone number  is Required",
                                    })}
                                />

                                {errors?.MobileNumber && (
                                    <p className={ContactClasses["error"]}>
                                        {errors?.MobileNumber?.message}
                                    </p>
                                )}
                            </div>
                            <div className={ContactClasses["form-control"]}>
                                <label htmlFor="Message">Message</label>
                                <textarea
                                    rows={4}
                                    placeholder="Your Message"
                                    {...register("Message", {
                                        required: "Message is Required",
                                    })}
                                />

                                {errors?.Message && (
                                    <p className={ContactClasses["error"]}>
                                        {errors?.Message?.message}
                                    </p>
                                )}
                            </div>
                            <input type={"submit"} />
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
