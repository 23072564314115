// 662932143
import React from "react";
import NavBar from "../HomePage/Navbar";
import Footer from "../HomePage/footer";
import ReactPlayer from "react-player";
import contentClasses from "./userdetails.module.css";
import { FiDownloadCloud } from "react-icons/fi";
import { GoBookmark } from "react-icons/go";
import { BsFillCollectionPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function MyLibraryContent() {
    const Navigate = useNavigate();
    return (
        <div className={contentClasses["library-content-wrapper"]}>
            <NavBar isActive="userdata" />
            <div className={contentClasses["libraryContent"]}>
                <div className={contentClasses["library-video"]}>
                    {" "}
                    <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={`https://vimeo.com/${662932143}`}
                        controls={true}
                    />
                </div>
                <h3>Competitive 1</h3>
                <div className={contentClasses["content-btns"]}>
                    <button>
                        <FiDownloadCloud />
                        <span>Notes PDF</span>
                    </button>
                    <button>
                        <GoBookmark />
                        <span>Library</span>
                    </button>
                    <button
                        onClick={() => {
                            Navigate("/series");
                        }}>
                        <BsFillCollectionPlayFill />
                        <span>View Series</span>
                    </button>
                </div>
                <p>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took galley of type
                    and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It
                    was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recent with
                    desktop publishing software like.
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default MyLibraryContent;
