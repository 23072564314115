import React, { useState, useEffect } from "react";
import Navbar from "../HomePage/Navbar";
import Footer from "../courses/courseFooter";
import { useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";
import booksclasses from "./books.module.css";
import Modal from "./modal.jsx";
import Booksignup from "./booksignup";
import CircleLoad from "../utils/Circularload";
import Download from "./Download.jsx";
function Booksvideo() {
    const [search] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageloading, setPageloading] = useState(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [count, setcount] = useState(null);

    // book signup
    const [signup, setsignup] = useState(false);
    const onbookOpenModal = () => setsignup(true);
    const onbookCloseModal = () => setsignup(false);

    // useEffect(() => {
    // let sessionId = localStorage.getItem("booksessionData");
    // if (
    //     sessionId != null &&
    //     sessionId != undefined &&
    //     sessionId.length > 0
    // ) {
    //     setPageloading(false);
    //     setsignup(false);
    //     setOpen(true);
    // } else {
    //     setPageloading(false);
    //     setsignup(true);
    // }

    // }, []);
    useEffect(() => {
        const checkAppInstalled = () => {
            const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            // Define your app's custom URL scheme or deep link
            const appDeepLink = `veteach://veteach.in/booksvideo?Videoid=${search.get(
                "Videoid"
            )}`;

            // Check if the user is on a mobile device
            const isMobile =
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    userAgent
                );

            if (isMobile) {
                // Check if the user is on an Android device
                if (/android/i.test(userAgent)) {
                    // Use a timeout to wait for the app to potentially open
                    const timeout = setTimeout(() => {
                        // Redirect to the Play Store if the app didn't open
                        window.location.href =
                            "https://play.google.com/store/apps/details?id=com.vasthi.veteach";
                    }, 2000); // Adjust the timeout as needed

                    // Attempt to open the app
                    window.location.href = appDeepLink;

                    // Clear the timeout if the app opens successfully
                    window.addEventListener("pagehide", () => {
                        clearTimeout(timeout);
                    });
                }

                // Check if the user is on an iOS device
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    // Use a timeout to wait for the app to potentially open
                    const timeout = setTimeout(() => {
                        // Redirect to the App Store if the app didn't open
                        window.location.href =
                            "https://apps.apple.com/in/app/veteach-elearning-platform/id1641883623";
                    }, 2000); // Adjust the timeout as needed

                    // Attempt to open the app
                    window.location.href = appDeepLink;

                    // Clear the timeout if the app opens successfully
                    window.addEventListener("pagehide", () => {
                        clearTimeout(timeout);
                    });
                }
            } else {
                setDownloadModal(true);
                // window.location.href =
                //     "https://play.google.com/store/apps/details?id=your.app.package";
            }
        };

        checkAppInstalled();
    }, []);
    console.log(loading);
    return (
        <>
            {pageloading && <CircleLoad />}
            <div>
                <Download open={downloadModal} setOpen={setDownloadModal} />
                {/* <Booksignup
                    onOpenModal={onbookOpenModal}
                    onCloseModal={onbookCloseModal}
                    open={signup}
                    setOpen={setOpen}
                /> */}
                {/* {open && (
                    <Modal
                        onOpenModal={onOpenModal}
                        onCloseModal={onCloseModal}
                        open={open}
                        loading={loading}
                        setloading={setLoading}
                        count={count}
                    />
                )} */}
                <Navbar />
                <div className={booksclasses["video_player"]}>
                    {!loading && (
                        <p></p>
                        // <ReactPlayer
                        //     height={"100%"}
                        //     width={"100%"}
                        //     playing={true}
                        //     url={`https://vimeo.com/${search.get("Videoid")}`}
                        //     controls={true}
                        // />
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Booksvideo;
