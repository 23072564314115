import React from "react";
import Navbar from "./Navbar";
import About from "./About";
import WhyChooseUs from "./WhyChooseUs";
import TopCourses from "./TopCourses";
import BrowsebyCategories from "./BrowsebyCategories";
import StudentSays from "./whatOurStudentSays";
import Playstore from "./playstore";
import Footer from "./footer";
function index() {
    return (
        <>
            <Navbar isActive="home" />
            <About />
            <WhyChooseUs />
            {/* <TopCourses /> */}
            <BrowsebyCategories />
            <StudentSays />
            <Playstore />
            <Footer />
        </>
    );
}

export default index;
