import React, { useState } from "react";
import loginClasses from "./login.module.css";
import { useForm } from "react-hook-form";
import { MdPhoneIphone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Services } from "../services";
function Signup() {
    const Navigate = useNavigate();
    const [otp, setotp] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const formdata = watch();
    const onSubmit = (data) => {
        if (!otp) {
            const body = {
                MobileNumber: formdata.MobileNumber,
                EmailID: formdata.EmailID,
                FirstName: formdata.FirstName,
            };
            Services.SignupSendOtp(JSON.stringify(body))
                .then((response) => {
                    if (response.Status == 1) {
                        setotp(true);
                    } else {
                        alert(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("some thing went wrong please try again");
                });
        }
        if (otp) {
            Services.VerifyOtp(JSON.stringify({ ...formdata }))
                .then((response) => {
                    if (response.Status == 1) {
                        sessionStorage.setItem(
                            "userdetails",
                            response.userDetails
                        );
                        sessionStorage.setItem("token", response.token);
                        Navigate("/");
                    } else {
                        alert(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("some thing went wrong please try again");
                });
        }
        console.log(data);
    };
    return (
        <div className={loginClasses.loginWrapper}>
            <div
                className={`${loginClasses["login-leftside"]} ${loginClasses["signup-leftside"]}`}>
                <div className={loginClasses["login-header"]}>
                    {!otp ? (
                        <>
                            <h3>Sign UP</h3>
                            <p>Please Sign UP to Continue</p>
                        </>
                    ) : (
                        <>
                            <h3>Verification</h3>
                            <h6>
                                Enter your 4-Digit OTP.
                                <br /> We’ve sent as sms with the OTP to <br />
                                <span>{formdata.MobileNumber}</span>
                            </h6>
                        </>
                    )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Your Name</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                className={
                                    errors?.FirstName &&
                                    loginClasses["input-error"]
                                }
                                placeholder="Enter Your Name"
                                {...register("FirstName", {
                                    required: "Please enter your Name",
                                })}
                            />
                            {
                                <p className={loginClasses["error"]}>
                                    {errors?.FirstName?.message}
                                </p>
                            }
                            <FaUserAlt
                                className={loginClasses["login-icon-name"]}
                                color="#EFE690"
                                size={22}
                            />
                        </div>
                    </div>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Email</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                type={"email"}
                                className={
                                    errors?.EmailID &&
                                    loginClasses["input-error"]
                                }
                                placeholder="please Enter Your Email ID"
                                {...register("EmailID", {
                                    required: "Please enter your Email",
                                })}
                            />
                            {
                                <p className={loginClasses["error"]}>
                                    {errors?.EmailID?.message}
                                </p>
                            }
                            <MdEmail
                                className={loginClasses["login-icon-name"]}
                                color="#EFE690"
                                size={26}
                            />
                        </div>
                    </div>
                    <div className={loginClasses["login-form-control"]}>
                        <label htmlFor="MobileNumber">Mobile Number</label>
                        <div className={loginClasses["login-input"]}>
                            <input
                                className={
                                    errors?.MobileNumber &&
                                    loginClasses["input-error"]
                                }
                                placeholder="Enter Mobile Number"
                                type="number"
                                disabled={otp}
                                {...register("MobileNumber", {
                                    required: "Please enter Your Mobile Number",
                                    minLength: {
                                        value: 10,
                                        message: "Enter 10 digit Mobile number",
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Enter 10 digit Mobile number",
                                    },
                                })}
                            />
                            {
                                <p className={loginClasses["error"]}>
                                    {errors?.MobileNumber?.message}
                                </p>
                            }

                            <MdPhoneIphone
                                className={loginClasses["login-icon"]}
                                color="#EFE690"
                                size={30}
                            />
                        </div>
                    </div>
                    {otp && (
                        <div className={loginClasses["login-form-control"]}>
                            <label htmlFor="MobileNumber">OTP</label>
                            <div className={loginClasses["login-input"]}>
                                <input
                                    className={
                                        errors?.OTP &&
                                        loginClasses["input-error"]
                                    }
                                    placeholder="Enter OTP"
                                    {...register("OTP", {
                                        required: "Please enter OTP",
                                    })}
                                />
                                {errors?.OTP && (
                                    <p className={loginClasses["error"]}>
                                        {errors?.OTP?.message}
                                    </p>
                                )}
                                <MdPhoneIphone
                                    className={loginClasses["login-icon"]}
                                    color="#EFE690"
                                    size={30}
                                />
                            </div>
                        </div>
                    )}
                    {!otp ? (
                        <input type={"submit"} value="Get OTP" />
                    ) : (
                        <input type={"submit"} value="Verify OTP" />
                    )}
                </form>
            </div>
            {/* <div className={loginClasses["login-rightside"]}>
                <img src={login_bg} alt="login" />
            </div> */}
        </div>
    );
}

export default Signup;
