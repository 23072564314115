// 662932143
import React from "react";
import NavBar from "../HomePage/Navbar";
import Footer from "../HomePage/footer";
import contentClasses from "./userdetails.module.css";
import { useNavigate } from "react-router-dom";
import Chapter from "../Assets/images/chapter.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
function Series() {
    const Navigate = useNavigate();
    const series = [
        "chapter 1",
        "chapter 2",
        "chapter 3",
        "chapter 4",
        "chapter 5",
    ];
    return (
        <div className={contentClasses["series-wrapper"]}>
            <NavBar isActive="userdata" />
            <div className={contentClasses["series"]}>
                {series.map((item) => (
                    <div className={contentClasses["series-item"]}>
                        <img src={Chapter} alt="chapter" />
                        <div className={contentClasses["series-item-header"]}>
                            <h3>{item}</h3>
                            <p>SSC Competitive 2020</p>
                        </div>
                        <MdKeyboardArrowRight
                            size={40}
                            style={{ justifySelf: "center" }}
                        />
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}

export default Series;
