import React from "react";
import homePageClasses from "./homepage.module.css";
import Circle from "../Assets/images/circle.svg";
import rightBg from "../Assets/images/studentsays_right.svg";
import leftbg from "../Assets/images/leftbgstudent.svg";
import profile from "../Assets/images/student_profile.png";
import pic from "../Assets/images/studentpic.svg";
import Carousal from "./carousal";
function whatOurStudentSays() {
    return (
        <div className={homePageClasses.studentsays}>
            <img
                className={homePageClasses.student_right_bg}
                src={rightBg}
                alt="right-bg"
            />

            <header>
                <h3>
                    What Our Student Says
                    <img src={Circle} alt="circle" />
                </h3>
            </header>
            <div className={homePageClasses.studentcards}>
                <Carousal />
                <img
                    className={homePageClasses.student_left_bg}
                    src={leftbg}
                    alt="right-bg"
                />
            </div>
        </div>
    );
}

export default whatOurStudentSays;
/* 

<div className={homePageClasses.student_reviews}>
                        <div className={homePageClasses.student_review}>
                            <div className={homePageClasses.student_detail}>
                                <img src={profile} alt="profilepic" />
                                <p>Satish Landkar</p>
                            </div>
                            <img src={pic} alt="img" />
                            <p>
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s, when an unknown
                                printer took industry's standard dummy text{" "}
                            </p>
                        </div>
                    </div>
*/
