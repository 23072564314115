import React, { useEffect, useState } from "react";
import modalclasses from "../HomePage/homepage.module.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../services";
import Store from "../store/store";
function Modal(props) {
    const Navigate = useNavigate();
    const setSubjects = Store((state) => state.setSubjects);
    const [universities, setUniversities] = useState([]);
    const [regulation, setRegulations] = useState([]);
    const [branch, setBranch] = useState([]);
    const [CourseName, setCourseName] = useState("");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const sem = [1, 2, 3, 4, 5, 6, 7, 8];
    const formData = watch();
    useEffect(() => {
        // get alll universities drop down menu
        Services.getAllUniversities()
            .then((response) => {
                if (response.Status === 1) {
                    setUniversities(response?.Universities);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
        // get all branches
        Services.getBranch()
            .then((res) => {
                if (res?.Status === 1) {
                    setBranch(res?.Courses);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    }, []);

    // get all regulations

    const universityHandler = (e) => {
        let id = e.target.value;

        // fetch regulation by univ id

        Services.getRegulationsByUnivId(id)
            .then((res) => {
                if (res?.Status === 1) {
                    setRegulations(res?.Regulations);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    };

    const RegulationClickHandler = () => {
        if (formData.University === "") {
            alert("please select university");
        }
    };

    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const changeCourse = (e) => {
        const [option] = e.target.selectedOptions;
        setCourseName(option.dataset.label);
    };

    //form submit
    const onSubmit = (data) => {
        console.log(data);

        let body = JSON.stringify({
            CourseID: Number(data.CourseID),
            Regulation: Number(data.Regulation),
            Sem: Number(data.Sem),
            Year: Number(data.Year),
            University: Number(data.University),
            CourseName,
        });

        // post request
        Services.SubjectByCourse(body)
            .then((res) => {
                if (res.Status == 1) {
                    setSubjects(res.Subjects);
                    sessionStorage.setItem(
                        "subjects",
                        JSON.stringify(res.Subjects)
                    );
                    Navigate("/Courses");
                } else {
                    alert("some thing went wrong please try again");
                }
            })
            .catch((err) => {
                "something went wrong please try again";
            });
    };
    return (
        <>
            <div
                className={modalclasses["subject-modal"]}
                onClick={CancelHandler}>
                <div
                    className={modalclasses["subject-modal-content"]}
                    onClick={openhandler}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={modalclasses["subject-formControl"]}>
                            <label htmlFor="university">
                                Select University <sup>*</sup>
                            </label>
                            <div>
                                <select
                                    className={`${
                                        errors?.University &&
                                        modalclasses["input-error"]
                                    }`}
                                    defaultValue=""
                                    {...register("University", {
                                        required: "Please Select University",
                                        onChange: (e) => {
                                            universityHandler(e);
                                        },
                                    })}>
                                    <option value="">Select University</option>
                                    {universities?.map((university) => (
                                        <option
                                            value={university?.UniversityID}
                                            key={university?.UniversityID}>
                                            {university?.UniversityName}
                                        </option>
                                    ))}
                                </select>
                                {errors?.University && (
                                    <p className={modalclasses.error}>
                                        {errors?.University?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={modalclasses["subject-formControl"]}>
                            <label htmlFor="regulation">
                                Select Regulation <sup>*</sup>
                            </label>
                            <div>
                                <select
                                    className={`${
                                        errors?.Regulation &&
                                        modalclasses["input-error"]
                                    }`}
                                    onClick={RegulationClickHandler}
                                    defaultValue=""
                                    {...register("Regulation", {
                                        required: "Please Select Regulation",
                                    })}>
                                    <option value="">Select Regulation</option>
                                    {regulation?.map((regulation) => (
                                        <option
                                            value={regulation?.RegulationID}
                                            key={regulation?.RegulationID}>
                                            {regulation?.Regulation}
                                        </option>
                                    ))}
                                </select>

                                {errors?.Regulation && (
                                    <p className={modalclasses.error}>
                                        {errors?.Regulation?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={modalclasses["subject-formControl"]}>
                            <label htmlFor="regulation">
                                Select Branch <sup>*</sup>
                            </label>
                            <div>
                                <select
                                    className={`${
                                        errors?.CourseID &&
                                        modalclasses["input-error"]
                                    }`}
                                    defaultValue=""
                                    {...register("CourseID", {
                                        required: "Please Select Branch",
                                        onChange: (e) => {
                                            changeCourse(e);
                                        },
                                    })}>
                                    <option value="">Select Branch</option>
                                    {branch.map((branch) => (
                                        <option
                                            data-label={branch.CourseName}
                                            key={branch?.CourseID}
                                            value={branch?.CourseID}>
                                            {branch.CourseName}
                                        </option>
                                    ))}
                                </select>

                                {errors?.CourseID && (
                                    <p className={modalclasses.error}>
                                        {errors?.CourseID?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={modalclasses["subject-formControl"]}>
                            <label htmlFor="Sem">
                                Select Sem <sup>*</sup>
                            </label>
                            <div>
                                <select
                                    className={`${
                                        errors?.Sem &&
                                        modalclasses["input-error"]
                                    }`}
                                    defaultValue=""
                                    {...register("Sem", {
                                        required: "Please Select Sem",
                                    })}>
                                    <option value="">Select Sem</option>
                                    {sem.map((item) => {
                                        return (
                                            <option value={item} key={item}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>

                                {errors?.Sem && (
                                    <p className={modalclasses.error}>
                                        {errors?.Sem?.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <input type={"submit"} />
                    </form>
                </div>
            </div>
        </>
    );
}

export default Modal;
