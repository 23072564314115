import CourseClasses from "./course.module.css";
import React, { useState, useEffect } from "react";
import demo from "../Assets/images/Courses/demo.png";
import { Rating } from "react-simple-star-rating";
import Spinner from "../spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Coursemodal from "../courses/Coursemodal";
function AllCourses() {
    const [allsubjects, setallsubjects] = useState([]);
    const [subject, setsubject] = useState();
    const [modal, setmodal] = useState(false);
    const [spinner, SetSpinner] = useState(true);
    const subjects = sessionStorage.getItem("subjects");
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrow: true,
        centerPadding: "1px",
        responsive: [
            {
                breakpoint: 1424,
                settings: {
                    variableWidth: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrow: true,
                },
            },
            {
                breakpoint: 920,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                    arrow: true,
                    // initialSlide: 2,
                },
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrow: true,
                },
            },
        ],
    };
    let sem;
    useEffect(() => {
        if (subjects) {
            SetSpinner(false);
            setallsubjects(JSON.parse(subjects));
        }
    }, []);
    return (
        <>
            {modal && <Coursemodal setmodal={setmodal} subject={subject} />}
            {spinner ? (
                <Spinner />
            ) : (
                <div className={CourseClasses["coursesData"]}>
                    <h3>
                        {allsubjects[0]?.Specialization}{" "}
                        {allsubjects[0]?.UniversityName}{" "}
                        {allsubjects[0]?.Regulation} Sem {allsubjects[0]?.Sem}
                    </h3>
                    <div className={CourseClasses.allsubjects}>
                        {allsubjects.map((item) => {
                            let demothumburl =
                                item?.SubjectThumbUrl === "NA"
                                    ? demo
                                    : item?.SubjectThumbUrl;
                            return (
                                <div
                                    className={CourseClasses["subject-item"]}
                                    subjectid={item?.SubjectID}
                                    onClick={(e) => {
                                        setsubject(item);
                                        sessionStorage.setItem(
                                            "selectedSubject",
                                            JSON.stringify(item)
                                        );
                                        setmodal(true);
                                    }}>
                                    <img src={demothumburl} alt="thumb-url" />
                                    <h2>{item.SubjectName}</h2>
                                    <p>{item.SingleLineContent}</p>
                                    <div className={CourseClasses.rating}>
                                        <div
                                            className={
                                                CourseClasses["rating-wrap"]
                                            }>
                                            <p className={CourseClasses.rate}>
                                                {item.Rating}
                                            </p>
                                            <Rating
                                                ratingValue={
                                                    (Number(item.Rating) *
                                                        100) /
                                                    5
                                                }
                                                initialValue={
                                                    (Number(item.Rating) *
                                                        100) /
                                                    5
                                                }
                                                size={25}
                                                readonly={true}
                                                fillColor={"#eb8a2f"}
                                            />
                                        </div>
                                        <p
                                            className={
                                                CourseClasses.ratingcount
                                            }>
                                            ({item.RatingCount})
                                        </p>
                                    </div>
                                    <p className={CourseClasses.bestSeller}>
                                        BestSeller
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

export default AllCourses;
