import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactLoading from "react-loading";
import { Services } from "../services";
function AdModal(props) {
    const [ads, setAds] = useState({});
    const [count, setcount] = useState(5);
    const [load, setload] = useState(true);
    useEffect(() => {
        if (count == 0) {
            props.onCloseModal();
            props.setloading(false);
        }
        Services.fetchAds()
            .then((response) => {
                setload(false);
                console.log(response);

                if (response.Status == 1) {
                    setAds(response.Ads);
                } else {
                    alert(response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("some thing went wrong please try again");
            });
        const intervalId = setInterval(() => {
            setcount(count - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [count]);
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onCloseModal}
                center
                closeOnOverlayClick={false}
                showCloseIcon={false}
                styles={{ overlay: { background: "black", opacity: "0.8" } }}>
                {load ? (
                    <ReactLoading type={"spin"} color={"#0000FF"} />
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "1000px",
                            maxHeight: "1000px",
                        }}>
                        <img
                            src={ads?.AdImage}
                            width={"100%"}
                            height={"100%"}
                        />
                        <p> closes in {count}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default AdModal;
