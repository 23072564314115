import React, { useState } from "react";
import CourseClasses from "./course.module.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiSkipNext } from "react-icons/bi";
import { MdOutlinePlayCircle } from "react-icons/md";
function TopicsVideos(props) {
    const [buttonid, setbuttonid] = useState("1");
    const [descriptionid, setdescriptionid] = useState({ id: "", state: "" });

    const buttonHanlder = (e) => {
        setbuttonid(e.currentTarget.getAttribute("id"));
    };

    const deschandler = (e) => {
        if (descriptionid.id == e.currentTarget.getAttribute("id")) {
            setdescriptionid(false);
        } else {
            setdescriptionid({
                id: e.currentTarget.getAttribute("id"),
                state: true,
            });
        }
    };
    const unitchangehandler = (e) => {
        console.log(e.target.value);
        props.setunitchange(e.target.value);
        const data = props.subjectContent.filter(
            (item) => item.Unit == e.target.value
        );
        console.log(data);
        props.setunitContent(data[0]);
    };
    console.log(props);
    return (
        <>
            <div className={CourseClasses.videonavbar}>
                <button
                    className={`${
                        buttonid === "1"
                            ? CourseClasses["activevideo"]
                            : CourseClasses["inactivevideo"]
                    }`}
                    id="1"
                    onClick={buttonHanlder}>
                    Topics
                </button>
                <button
                    className={`${
                        buttonid === "2"
                            ? CourseClasses["activevideo"]
                            : CourseClasses["inactivevideo"]
                    }`}
                    id="2"
                    onClick={buttonHanlder}>
                    Syllabus
                </button>
            </div>
            {buttonid == 2 ? (
                <div className={CourseClasses.Syllabus}>
                    {props.syllabus.map((item) => {
                        return (
                            <>
                                <button id={item.No} onClick={deschandler}>
                                    <h3>Unit {item.No}</h3>
                                    <MdKeyboardArrowDown
                                        size={25}
                                        className={CourseClasses.downarrow}
                                    />
                                </button>
                                {descriptionid.id == item.No &&
                                    descriptionid.state && (
                                        <p>{item.Unit_Body}</p>
                                    )}
                            </>
                        );
                    })}
                </div>
            ) : (
                <div className={CourseClasses.upnext}>
                    <div className={CourseClasses["upnext-header"]}>
                        <div>
                            <BiSkipNext size={28} />
                            <p>Up Next</p>
                        </div>
                        <select
                            onChange={unitchangehandler}
                            defaultValue={props.unitchange}>
                            {props.subjectContent?.map((item) => {
                                return (
                                    <option value={item.Unit}>
                                        Unit-{item.Unit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className={CourseClasses.videos}>
                        {props.unitcontent?.Topics?.map((item) => {
                            return (
                                <div
                                    className={CourseClasses["video-item"]}
                                    onClick={() => {
                                        props.setSpinner(true);
                                        props.setvideoid(item);
                                        setTimeout(() => {
                                            props.setSpinner(false);
                                        }, 1000);
                                    }}>
                                    <img
                                        src={item.TopicThumbUrl}
                                        alt="TopicThumbUrl"
                                    />
                                    <MdOutlinePlayCircle
                                        className={`${CourseClasses.playicon}`}
                                        color="white"
                                        size={42}
                                    />
                                    <p>{item.TopicName}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

export default TopicsVideos;
