import React from "react";
import footerClasses from "./footer.module.css";
import button_bg from "../Assets/images/button_bg.svg";
import { IoIosArrowUp } from "react-icons/io";
import logo from "../Assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";

import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import { BsYoutube } from "react-icons/bs";
function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <footer>
            <div className={footerClasses.wrapper}>
                <div className={footerClasses.logo}>
                    <div className={footerClasses.images}>
                        <img src={logo} alt="logo" />
                        {/* <img src={logo2} alt="logo" /> */}
                    </div>
                    <p>
                        Start Learning From India’s Smart Learning Platform{" "}
                        <br /> VEEDUCARE PRIVATE LIMITED{" "}
                    </p>
                    <div className={footerClasses.iconswrap}>
                        <a href="" target={"_blank"} rel="noopener noreferrer">
                            {" "}
                            <BsFacebook
                                className={footerClasses.social}
                                size={25}
                            />
                        </a>

                        <a href="" target={"_blank"} rel="noopener noreferrer">
                            <AiOutlineInstagram
                                className={footerClasses.social}
                                size={30}
                            />
                        </a>
                        <a href="" target={"_blank"} rel="noopener noreferrer">
                            <AiFillLinkedin
                                className={footerClasses.social}
                                size={30}
                            />
                        </a>
                        <a href="" target={"_blank"} rel="noopener noreferrer">
                            <BsYoutube
                                className={footerClasses.social}
                                size={26}
                            />
                        </a>
                    </div>
                </div>
                <div className={footerClasses.nav_wrapper}>
                    <nav className={` ${footerClasses.nav}`}>
                        <h3>Useful Links</h3>
                        <NavLink to="/Terms">Terms & Conditions</NavLink>
                        <NavLink to="/About">About</NavLink>
                        <NavLink to="/contactUs">Contact Us </NavLink>
                        <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
                    </nav>
                    <div className={footerClasses.download}>
                        <h3>Download</h3>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.vasthi.veteach"
                            target={"_blank"}
                            rel="noopener noreferrer">
                            <img src={button_bg} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={footerClasses.Copyright}>
                <p> © Veteach 2022</p>
                <button onClick={scrollToTop}>
                    <IoIosArrowUp size={25} />
                </button>
            </div>
        </footer>
    );
}

export default Footer;
