import { Routes, Route } from "react-router-dom";
import HomePage from "./Components/HomePage/index";
import Login from "./Components/login/login";
import Courses from "./Components/courses/courses";
import CourseVideo from "./Components/courses/coursevideos";
import SignUp from "./Components/login/signup";
import SignupDetails from "./Components/login/signupDetails";
import Subscriptions from "./Components/userDetails/Subscriptions";
import Library from "./Components/userDetails/Library";
import LibraryContent from "./Components/userDetails/MyLibraryContent";
import Series from "./Components/userDetails/Series";
import Refer from "./Components/userDetails/refer";
import Support from "./Components/userDetails/Support";
import Feedback from "./Components/userDetails/feedback";
import Editprofile from "./Components/userDetails/editporfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactUs from "./Components/contactus/contactus";
import Privacy from "./Components/privacyPolicy/privacy";
import Terms from "./Components/Terms/Terms";
import About from "./Components/AboutUs/about";
import BooksVideo from "./Components/booksvideo/booksvideo"
function App() {


    return (
        <div className="App">
            <ToastContainer />
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route
                    exact
                    path="/signup/details"
                    element={<SignupDetails />}
                />
                <Route exact path="/Courses" element={<Courses />} />
                <Route exact path="/About_us" element={<About />} />
                <Route exact path="/library" element={<Library />} />
                <Route exact path="/privacyPolicy" element={<Privacy />} />
                <Route exact path="/Terms" element={<Terms />} />
                <Route
                    exaact
                    path="/libraryContent"
                    element={<LibraryContent />}
                />
                <Route
                    exact
                    path="/subjectVideo/:id"
                    element={<CourseVideo />}
                />
                <Route
                    exact
                    path="/Subscriptions"
                    element={<Subscriptions />}
                />
                <Route exact path="/contactUs" element={<ContactUs />} />
                <Route exact path="/series" element={<Series />} />
                <Route exact path="/Refer" element={<Refer />} />
                <Route exact path="/support" element={<Support />} />
                <Route exact path="/feedback" element={<Feedback />} />
                <Route exact path="/edit-profile" element={<Editprofile />} />
                <Route exact path="/booksvideo" element={<BooksVideo />} />
            </Routes>
        </div>
    );
}

export default App;
