import React from "react";
import { css } from "@emotion/react";
import ReactLoading from "react-loading";
import "react-responsive-modal/styles.css";
import utilitiesClasses from "./utilities.module.css";
function circleLoad() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        z-index: 1000;
    `;
    return (
        <div className={utilitiesClasses["circle-loader"]}>
            <ReactLoading type={"spin"} color={"#0000FF"} />
        </div>
    );
}

export default circleLoad;
