import React, { useState } from "react";
import Navbar from "../HomePage/Navbar";
import SubscriptionClasses from "./userdetails.module.css";
import Usernav from "./Usernav";
import Footer from "../HomePage/footer";
import sample from "../Assets/images/subscription_sample.svg";
import ProgressBar from "@ramonak/react-progress-bar";
function Subscriptions() {
    const [subscriptionState, setSubscriptionState] = useState("ongoing");
    const subject = [
        { name: "Competitive 1" },
        { name: "Competitive 1" },
        { name: "Competitive 1" },
    ];
    const buttonHandler = (e) => {
        setSubscriptionState(e.currentTarget.getAttribute("id"));
    };
    return (
        <>
            <Navbar isActive="userdata" />
            <div className={SubscriptionClasses["subscription-wrapper"]}>
                <div className={SubscriptionClasses["subscription-leftside"]}>
                    <Usernav isActive="subscriptions" />
                </div>
                <div className={SubscriptionClasses["subscription-rightSide"]}>
                    <h3>My subscriptions</h3>
                    <h2>Please login to our app to access purchased content</h2>
                    {/* <div className={SubscriptionClasses["subscription-nav"]}>
                        <button
                            className={
                                subscriptionState === "ongoing"
                                    ? SubscriptionClasses["sub-btn-active"]
                                    : SubscriptionClasses["sub-btn-inactive"]
                            }
                            id="ongoing"
                            onClick={buttonHandler}>
                            Ongoing
                        </button>
                        <button
                            className={
                                subscriptionState === "completed"
                                    ? SubscriptionClasses["sub-btn-active"]
                                    : SubscriptionClasses["sub-btn-inactive"]
                            }
                            id="completed"
                            onClick={buttonHandler}>
                            Completed
                        </button>
                    </div> */}
                    {/* <div
                        className={
                            SubscriptionClasses["subscription-subjects"]
                        }>
                        {subject.map((item) => (
                            <div
                                className={
                                    SubscriptionClasses["subscription-subject"]
                                }>
                                <img src={sample} alt="" />
                                <div className={SubscriptionClasses["content"]}>
                                    <p>{item.name}</p>
                                    <div
                                        className={
                                            SubscriptionClasses[
                                                "progress-wrapper"
                                            ]
                                        }>
                                        <div
                                            className={
                                                SubscriptionClasses["progress"]
                                            }>
                                            <h3>Progress</h3>
                                            <p>12/20 Lessons</p>
                                        </div>
                                        <div
                                            className={
                                                SubscriptionClasses["due-time"]
                                            }>
                                            <h3>Due Time</h3>
                                            <p>09 Oct 2022</p>
                                        </div>
                                    </div>
                                    <ProgressBar
                                        height="15px"
                                        customLabel=" "
                                        bgColor="#f55700"
                                        completed={55}
                                    />
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Subscriptions;
