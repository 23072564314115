import React from "react";
import Courseclasses from "./course.module.css";
import { useNavigate } from "react-router-dom";
import { Services } from "../services";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
function Coursemodal(props) {
    const date = new Date(props?.subject?.UpdatedAt);

    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const courseHandler = (e) => {
        Navigate(`/subjectVideo/${e.currentTarget.getAttribute("id")}`);
    };
    return (
        <>
            <div
                className={Courseclasses["course-modal"]}
                onClick={CancelHandler}>
                <div
                    className={Courseclasses["course-modal-content"]}
                    onClick={openhandler}>
                    <h3>{props?.subject?.SubjectName}</h3>
                    <h4>
                        last update {String(date.getDate()).padStart(2, "0")}-
                        {String(date.getMonth() + 1).padStart(2, "0")}-
                        {date.getFullYear()}
                    </h4>
                    <h5>
                        <BsFillPlayCircleFill size={15} />
                        <BiTime size={18} />
                        Time {props.subject.SubjectTime} Hours
                    </h5>
                    <p>{props?.subject?.SubjectDescription}</p>
                    <p>{props?.subject?.SubjectPoints}</p>
                    <button
                        onClick={courseHandler}
                        id={props?.subject?.SubjectID}>
                        Subscribe to Watch Full Video
                    </button>
                </div>
            </div>
        </>
    );
}

export default Coursemodal;
