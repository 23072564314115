import React from "react";
import HomePageClasses from "./homepage.module.css";
import phone from "../Assets/images/phone.svg";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function playstore() {
    return (
        <div className={HomePageClasses.playstorewrapper}>
            <div className={HomePageClasses.playstore_leftside}>
                <h3>
                    Get it on <br />
                    Playstore
                </h3>
                <a
                    href="https://play.google.com/store/apps/details?id=com.vasthi.veteach"
                    target={"_blank"}
                    rel="noreferrer">
                    <IoLogoGooglePlaystore
                        className="playstore-icon"
                        size={38}
                        color="#FFFFFF"
                    />
                    <div>
                        <p>Available on</p>
                        <h5>Google Play</h5>
                    </div>
                </a>
            </div>
            <div className={HomePageClasses.rightside}>
                <img className={HomePageClasses.phone} src={phone} alt="" />
            </div>
        </div>
    );
}

export default playstore;
