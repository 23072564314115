const fetchmethod = (url) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}${url}`).then((response) =>
        response.json()
    );
};
const postmethod = (url, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method: "POST",
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};
const putmethod = (url, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method: "PUT",
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};
// signup

const SignupSendOtp = (body) => {
    return postmethod("/user/signup/sendotp", body);
};

//signup verify otp
const VerifyOtp = (body) => {
    return postmethod("/user/signup", body, "");
};

//login send otp
const loginSendOtp = (body) => {
    return postmethod("/user/login/sendotp", body, "");
};

//loginveridyotp
const loginVerifyOtp = (body) => {
    return postmethod("/user/login/verifyotp", body, "");
};
// university Dropdown
const getAllUniversities = () => {
    return fetchmethod(`/universities/list`);
};

// get all regulation based on university id drop down menu
const getRegulationsByUnivId = (id) => {
    return fetchmethod(`/regulations/byuniversity/${id}`);
};

// get all branches drop down menu
const getBranch = () => {
    return fetchmethod(`/user/web/courses`);
};

// subject by course
const SubjectByCourse = (body) => {
    return postmethod("/subjects/web/bycourse", body, "");
};

//subjet content by subject id

const subjectContentBySubjectId = (id) => {
    return fetchmethod(`/subjects/web/subjectcontents/${id}`);
};

// fetch ads 
const fetchAds = () => {
    return fetchmethod(`/books/user/latestAds`);
};


// support
const support = (body, token) => {
    return postmethod("/user/support", body, token);
};

//  booking signup 

const bookingsignup = (body, token) => {
    return postmethod("/books/add_bookuser", body, token);
};

//booking signup verify
const bookingsignupVerify = (body, token) => {
    return postmethod("/books/verifybookuser", body, token);
};

// update userdetails

const UpdateUserDetails = (body, token) => {
    return putmethod("/user/update", body, token);
};

// get all promocodes

export const Services = {
    getAllUniversities,
    getRegulationsByUnivId,
    getBranch,
    SubjectByCourse,
    subjectContentBySubjectId,
    SignupSendOtp,
    VerifyOtp,
    loginSendOtp,
    loginVerifyOtp,
    support,
    UpdateUserDetails,
    fetchAds,
    bookingsignup,
    bookingsignupVerify
};
